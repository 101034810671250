/* @import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://code.getmdl.io/1.3.0/material.blue_grey-blue.min.css";
@import "https://fonts.googleapis.com/css?family=Roboto";
@import "https://fonts.googleapis.com/earlyaccess/notosanstc.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/dialog-polyfill/0.4.10/dialog-polyfill.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.0/normalize.min.css"; */



html,
body,
.mdl-textfield__input,
.mdl-button {
  font-family: "Roboto", "Noto Sans TC", sans-serif;
}

html,
body,
div#root {
  height: 100vh;
  background-color: #40B9D8
}
